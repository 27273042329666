import React from "react";
import { graphql } from 'gatsby';

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import BlogPost from '../template/pages/BlogPost';

const Page = (props) => {
    return <>
      <Layout {...props}>
          <SEO />
          <BlogPost {...props} />
      </Layout>
    </>
}

export default Page;
